const PALLET = {
  BACKGROUND: '#EFF3F5',
  COLOR_01: '#768693',
  COLOR_02: '#1E9FD3',
  COLOR_03: '#43A0E7',
  COLOR_04: '#449FE7',
  COLOR_05: '#1884E3',
  COLOR_06: '#3A4D5C',
  COLOR_07: '#0A9FDE',
  COLOR_08: '#0984E3',
  COLOR_09: '#B2B2B2',
  COLOR_10: '#645D5D',
  COLOR_11: '#707070',
  COLOR_12: '#EBEBEB',
  COLOR_13: '#FFFFFF',
  COLOR_14: '#969696',
  COLOR_15: '#00BF78',
  COLOR_16: '#333333',
  COLOR_17: '#ff0000',
  COLOR_18: '#e68a00'
}

export default PALLET
