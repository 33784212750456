export const maskCurrency = (value: string | number): string => {
  const money =
    typeof value === 'string' ? Number(value.replace(/\D/g, '')) / 100 : value

  return money.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
}

export const unMaskCurrency = (value: string): number => {
  return typeof value === 'number'
    ? value
    : Number(value.replace(/\D/g, '')) / 100
}

export const maskCPF = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskCEP = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

export const maskCNPJ = (value: string): string => {
  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números
}

export const maskPhone = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})(\d+?)$/, '$1')
}

export const removeMask = (value: string): string => {
  return value.replace(/\D/g, '')
}

export const removeComma = (value: string): number => {
  return Number(value.replace(',', '.'))
}

export const maskLatitudeLongitude = (value: string): string => {
  return value.replace(/[^\d.]|(?<=\.\d*)\./g, '')
}
