import React from 'react'

const NotFound: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div>
      <h2>Página não encontrada</h2>
    </div>
  )
}

export default NotFound
